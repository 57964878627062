import {
  LOADING,
  ERRORS,
  FOOTER_LOADING,
  POPUP_CAMPAIGN,
  CONTACT_US_SUBMIT,
  CONTACT_US_ERRORS,
  POST_LOADING,
  GET_CONTACT_US_DATA,
} from "../types";
import fetcher from "../../common/axiosInstance";
import axios from "axios";
import { CLIENT_SECRET, apiUrl } from "@globalConfig";

export const loading = (status) => {
    if(__IS_CSR__ && window.SSR_PERIOD_PASSED) {
     return {
            type: LOADING,
            payload: status,
        };
    }
    return {type: null};
};

export const postLoading = (status) => {
  return {
    type: POST_LOADING,
    payload: status,
  };
};

export const footerLoading = (status) => {
  return {
    type: FOOTER_LOADING,
    payload: status,
  };
};


export const getErrors = (errors) => {
  return {
    type: ERRORS,
    payload: errors,
  };
};


export const getCampagin = (lang) => async (dispatch) => {
  await dispatch(popupCampagin(lang));
};

export const popupCampagin = (lang) => async (dispatch) => {
  const res = await fetcher().post(`/site/popup-campaign?include=contact`);
  if (res.data && res.data.success && res.data.data != null) {
    dispatch({
      type: POPUP_CAMPAIGN,
      payload: res.data,
    });
  } else {
    dispatch({
      type: POPUP_CAMPAIGN,
      payload: { data: "stopReq" },
    });
  }
};


export const SubmitContactUs = (formData, callback) => async (dispatch) => {
  dispatch(loading(true));
  await dispatch(contactUsSubmit(formData, callback));
  dispatch(loading(false));
};


export const contactUsSubmit = (formData, callback) => async (dispatch) => {
  const res = await fetcher().post(`/site/contact-us`, formData);

  if (
    res.data &&
    res.data.success &&
    res.data.data != null &&
    res?.data?.code != "230"
  ) {
    dispatch({
      type: CONTACT_US_SUBMIT,
      payload: res.data,
    });
    callback && callback();
  } else {
    dispatch({
      type: CONTACT_US_ERRORS,
      payload: res.data,
    });
  }
};


export const sendOtp =
  ({ phoneNumber, otpLength, postId, orderId, cb, fcb }) =>
  async (dispatch) => {
    dispatch(footerLoading(true));
    const formData = new FormData();
    formData.append("phone_number", phoneNumber);
    formData.append("otp_length", otpLength ? otpLength : 4);
    postId && formData.append("post_id", postId);
    orderId && formData.append("order_id", orderId);

    const res = await fetcher().post("otp/send", formData);

    if (res?.data?.code === 200) if (cb) await cb();

    if (res?.data?.code === 230 )
      if (fcb) fcb(res.data.errors);

    dispatch(footerLoading(false));
  };

export const verifyOtp =
  ({ phoneNumber, otp, scenario, cb, failureCb, fullName, cityId }) =>
  async (dispatch) => {
    dispatch(footerLoading(true));
    const formData = new FormData();
    formData.append("phone_number", phoneNumber);
    formData.append("otp", otp);
    formData.append("scenario", scenario);
    fullName && formData.append("full_name", fullName);
    cityId && formData.append("city_id", cityId);

    const res = await fetcher().post("otp/verify", formData);

    if (res?.data?.code === 200) if (cb) cb(res.data.data);
    if (res?.data?.code === 230) if (failureCb) failureCb(res.data.errors);

    dispatch(footerLoading(false));
  };

export const healthcheck = (cb) => async (dispatch) => {
  dispatch(loading(true));

  const healthcheckEndPoint = `${apiUrl.replace(
    /\/syarah_v1/,
    ""
  )}/healthcheck`;

  const res = await axios({
    method: "get",
    url: healthcheckEndPoint,
    headers: {
      token: CLIENT_SECRET,
    },
  });

  if (res?.data?.code === 200) if (cb) cb(res.data.data);

  dispatch(loading(false));
};


export const getContactUsData = () => async (dispatch) => {
  dispatch(loading(true));

  const res = await fetcher().get(`/site/static-responses?action=contact-us`);

  if (res.data.data) {
    dispatch({
      type: GET_CONTACT_US_DATA,
      payload: res.data.data,
    });
  }

  dispatch(loading(false));
};
