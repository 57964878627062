import SlideContent from "./SlideContent/SlideContent";
import stl from "../../HomePage.module.css";
import useIsMobile from "@hooks/useIsMobile";
import useIsFirstInteraction from "../../../../common/hooks/useIsFirstInteraction";
import { FC, useEffect, useState } from "react";
import { thumbUrl } from "../../../../utils/imagesHelpers";

type ImageWrapper = {
  image: string;
  images: {
    image_small: string;
    image_large: string;
  };
};
type Props = {
  usp_slider: {
    title: string;
    images: ImageWrapper[];
  };
  isEager: boolean;
};

const UspSlider: FC<Props> = ({ usp_slider, isEager = false }) => {
  const isFirstInteraction = useIsFirstInteraction();
  const isMobile = useIsMobile();
  if (!usp_slider?.images || !usp_slider?.title) return null;
  const has3 = usp_slider.images.length <= 3;
  const [images, setImages] = useState(
    usp_slider.images.slice(0, isMobile ? 2 : 4)
  );

  useEffect(() => {
    if (!isFirstInteraction) return;
    setImages(usp_slider.images);
  }, [isFirstInteraction]);

  const items = images.map((img) => {
    return {
      bgColor:
        "linear-gradient(0deg, rgba(241,246,252,1) 0%, rgba(249,251,254,1) 100%)",
      imageUrl: thumbUrl(
        img.image,
        "homepage-usp-slider-images-v1",
        isMobile ? "0x300" : "0x426"
      ),
      progressiveImageUrl: !isEager
        ? null
        : thumbUrl(
            img.image,
            "homepage-usp-slider-images-v1",
            isMobile ? "0x154" : "0x300"
          ),
    };
  });
  return (
    <SlideContent
      isEager={isEager}
      has3={has3}
      titleClass={stl.CustomContainer}
      containerClass={stl.CustomContainer}
      title={usp_slider.title}
      items={items}
    />
  );
};

export default UspSlider;
