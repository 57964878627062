// Define type aliases for scalability and easy updates
type PrefixType = "online-v1" | "hero-v1" | "homepage-usp-slider-images-v1";
const SizeArray = ["0x99", "0x154", "0x300", "0x426", "0x683", "0x960"] as const;
type SizeType = typeof SizeArray[number];

export const thumbUrl = (url: string, prefix: PrefixType, size: SizeType, force: boolean = false): string => {
    if (!url) return null;
    if (url.includes("no_img")) return url;
    // Check if the URL includes any size from the SizeArray
    const matchedSize = SizeArray.find(s => url.includes(s));

    if (matchedSize) {
        // If it does, replace that size with the new size
        return url.replace(matchedSize, size);
    } else if (url.includes("00x00")) {
        // If it doesn't, keep the existing logic of replacing "00x00" with the new size
        return url.replace("00x00", size);
    }
    // Parse the input URL
    const originalUrl = new URL(url);
    if (force === false && !originalUrl.pathname.includes('photos-thumbs')) return url;

    // Construct the new pathname by inserting prefix and size into the existing path
    // Split the original pathname to remove the leading part specific to the current format
    // Example split: ['/syarah', '/online', '/hero', '/1710158609-181.webp']
    const pathSegments = originalUrl.pathname.split('/').filter(segment => segment); // filter to remove any empty strings due to leading '/'

    // Rebuild the path with new structure
    const newPathname = `/photos-thumbs/${prefix}/${size}/${pathSegments.join('/')}`;

    // Construct the new URL
    const newUrl = `${originalUrl.protocol}//${originalUrl.host}${newPathname}`;

    return newUrl;
}


export const processImageUrl = (imageUrl) => {
    if (!imageUrl) return null;
    const searchString = "#testImage";

    // Check if the code is running in a browser environment and the URL contains the test query parameter.
    if (
        typeof window !== "undefined" &&
        window.location.href.includes(searchString)
    ) {
        // If the condition is met, call the 'convertToOld' function to modify the URL.
        const modifiedImageUrl = convertToOld(imageUrl);
        return modifiedImageUrl;
    } else {
        // If the condition is not met, return the original URL.
        return imageUrl;
    }
};


var convertToOld = (path) => {
    // Check if the path includes "photos-thumbs". If not, return it unchanged.
    if (!path.includes("photos-thumbs")) return path;

    // Split the URL path into components.
    const [_, __, ___, basePath, version, size] = path.split("/");

    // Modify the URL path as needed.
    const parts = path.split("/");
    parts.splice(-1, 0, size);
    const splitUrl = parts
        .join("/")
        .replace(`${basePath}/${version}/${size}/`, "")
        .split(".");
    const newUrl = splitUrl.slice(0, splitUrl.length - 1).join(".");

    // Return the modified URL.
    return newUrl;
};
