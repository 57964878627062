import { useEffect, useState } from 'react';

function useIsIOS() {
  const [isIOS, setIsIOS] = useState(false);

  useEffect(() => {
    if (__IS_CSR__) {
      const userAgent = window.navigator.userAgent;
      const iOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
      setIsIOS(iOS);
    }
  }, []);

  return isIOS;
}

export default useIsIOS;