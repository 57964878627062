import {useTranslation as useTranslation_i18n} from "react-i18next";
import {TypeI18nNamespaces} from "./types";
import {useLang} from "@/common/Contexts/LanguageContext";

type TranslationParams = {
    ns?: TypeI18nNamespaces;
    [key: string]: any;
};

type TFunctionType = {
    t: (key: string, params: TranslationParams) => string;
    i18n: { language: "ar" | "en" };
}

const useTranslation = (ns: TypeI18nNamespaces[] = ["web_common"]): TFunctionType => {
    const lang = useLang();
    const {t} = useTranslation_i18n(ns);
    const translationFunc = (key: string, params: TranslationParams={}): string => {
        if(__IS_CSR__) {
            let lbl: string;
            if(!params.ns){
                lbl= key;
            } else {
                lbl= (window.initialI18nStore?.[lang]?.[params.ns]?.[key]) ?? key;
            }
            lbl = lbl && typeof lbl === "string" ? lbl : JSON.stringify(key);
            return (lbl || "").replace(/{{(.*?)}}/g, (match, p1) => params[p1.trim()] || match);

        }
        return t(key, params) as unknown as string;
    }

    return {t: translationFunc, i18n: {language: lang}};
}
export default useTranslation;