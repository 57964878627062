import {useContext} from "react";
import IsMobileContext from "../Contexts/IsMobileContext";

export const useIsMobile = () => {
    if(typeof window !== 'undefined') {
        return window.innerWidth < 1000;
    }
    return useContext(IsMobileContext);
};

export default useIsMobile;