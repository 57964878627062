import stl from "./SlideContent.module.css";
import { FC } from "react";
import ProgressiveImage from "@componentsShared/ProgressiveImage";

type Props = {
  title: string;
  items: {
    bgColor?: string;
    imageUrl: string;
    progressiveImageUrl?: string;
    hide?: boolean;
    title?: string;
    text?: string;
  }[];
  isEager: boolean;
  titleClass?: string;
  containerClass?: string;
  has3?: boolean;
};

const SlideContent: FC<Props> = ({
  title,
  items,
  isEager,
  titleClass,
  containerClass,
  has3,
}) => (
  <div className={stl.pCont}>
    <div className={titleClass}>
      {isEager ? (
        <h1 className="big">{title}</h1>
      ) : (
        <h2 className="big">{title}</h2>
      )}
    </div>
    <div
      className={[
        containerClass || "",
        stl.container,
        has3 ? stl.has3 : "",
      ].join(" ")}
    >
      {items?.map((item, idx) => {
        return (
          <div
            key={idx}
            className={stl.cardContainer}
            style={{ background: item.bgColor, opacity: item.hide ? "0" : "1" }}
          >
            <ProgressiveImage
              src={item.imageUrl}
              progressiveSmallImage={item.progressiveImageUrl}
              width={267}
              height={267}
              loading={isEager ? "eager" : "lazy"}
              fetchpriority={isEager ? "high" : "low"}
              alt={item.title || item.imageUrl}
            />
            {item.title && <strong>{item.title}</strong>}
            {item.text && <p>{item.text}</p>}
          </div>
        );
      })}
    </div>
  </div>
);

export default SlideContent;
