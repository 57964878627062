import "../../_FIX_ME_STYLES/home.css";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import Layout from "../../components/layout/Layout";
import HomePage from "../../components/homePage/HomePage";
import {
  getIndexData,
  updateSelectedTab,
} from "../../redux/actions/homeActions";
import { getSearchSuggestions } from "../../redux/actions/searchActions";
import {
  createHomeSeoUrl,
  createInternalUrlWithParams,
  setHeaderTags,
} from "../../utils/common";
import stl from "./index.module.css";
import {useLang} from "../../common/Contexts/LanguageContext";
import {useTranslation} from "@i18n";

function Home() {
  const { t } = useTranslation(["web_home", "web_common"]);
  const lang = useLang();
  let history = useNavigate();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);

  const [flashLoading, setFlashLoading] = useState(false);
  const location = useLocation();
  const isNew =
    decodeURI(location.pathname).indexOf("/home/new-cars") >= 0;

  const USED_INCLUDES =
    "home:is_online(1):is_new(0),testimonial_videos,contact_us,mobile_posts:count(8):is_new(0),default_post:count(8):is_new(0):is_online(1),heros:is_new(0):is_online(1):hero_thumb_size(683),deal_offers:is_new(0):is_online(1):type(web),faq,users_feedback,heros_area:is_new(0),milage_ranges,price_ranges:is_new(0),homepage_sliders:is_new(0)";
  const NEW_INCLUDES =
    "home:is_online(1):is_new(1),testimonial_videos,contact_us,mobile_posts:count(8):is_new(1),default_post:count(8):is_new(1):is_online(1),heros:is_new(1):is_online(1):hero_thumb_size(683),deal_offers:is_new(1):is_online(1):type(web),faq,users_feedback,heros_area:is_new(1),milage_ranges,price_ranges:is_new(1),homepage_sliders:is_new(1)";

  useEffect(() => {
    document.title = `${t("سيارات للبيع في السعودية", {
      ns: "web_common",
    })} | ${t("موقع سيارة", {
      ns: "web_common",
    })} `;
    if (state.home.homeDataNew.post_online_count == "") {
      let includes = USED_INCLUDES;
      if (isNew) {
        includes = NEW_INCLUDES;
      }
      dispatch(getIndexData(includes));
    }
  }, []);
  const link = createHomeSeoUrl(
    true,
    decodeURI(location.pathname),
    lang
  );
  const logoLinks = createHomeSeoUrl(
    false,
    decodeURI(location.pathname),
    lang
  );
  return (
    <Layout
      hasNavBar
      pageName="home"
      hasMobFooter
      homeSearchHeader
      loading={state.common.loading}
      hideAdvSearchMobile
      disableLoader
      hasContactUs
      createLanguageSwitcherLink={link}
      createLogoLanguageLink={logoLinks}
      updateTabs={(val) => {
        if (state.home.selectedTab !== val) {
          let path = "/";
          let includes = USED_INCLUDES;
          if (val == 1) {
            path = "/home/new-cars";
            includes = NEW_INCLUDES;
          }
          history(createInternalUrlWithParams(path));
          dispatch(updateSelectedTab(val));
          dispatch(getIndexData( includes, false));
          setFlashLoading(true);
          window.scrollTo(0, 0);
        }
      }}
      contact={state.common.contactUs}
      loggedin={state.auth.loggedin}
      notification={state.auth.notification}
      showPopCampaign
      authenticationMsg
      mainClass={[
        state.common.loading ? stl.flashLoading : undefined,
        stl.trans,
      ].join(" ")}
      revampedHEading={true}
      getSearchSuggestions={(values, callBack, initialData) => {
        dispatch(getSearchSuggestions(values, callBack, initialData));
      }}
      // hideOrangeHeadBtn
    >
      {setHeaderTags(
         `${t("سيارات للبيع في السعودية", {
              ns: "web_common",
            })} | ${t("موقع سيارة", {
              ns: "web_common",
            })} `
      )}
      <HomePage
        data={state.home.homeDataNew}
        isNew={isNew}
        isUsed={false}
        marketingData={state.home.marketingData}
        selectedTab={state.home.selectedTab}
        flashLoading={state.common.loading}
      />
    </Layout>
  );
}
export default Home;
