import {useEffect, useRef, useState} from "react";
import useIsFirstInteraction from "./useIsFirstInteraction";

const LazyLoadComponent = ({loadComponent, waitInterActive = true,renderOnSSR=true}) => { // Added "const" keyword before the function name
    if(__IS_SSR__ && renderOnSSR){
        return loadComponent();
    }

    const isFirstInteraction = useIsFirstInteraction();
    const [shouldLoad, setShouldLoad] = useState(false);
    const targetRef = useRef(null);

    useEffect(() => {
        if (waitInterActive && !isFirstInteraction) {
            return;
        }
        const observer = new IntersectionObserver(
            (entries) => {
                const entry = entries[0];
                if (entry.isIntersecting) {
                    setShouldLoad(true);
                    observer.disconnect();
                }
            },
            {root: null, rootMargin: "0px 0px 50px 0px", threshold: 0.99}
        );

        if (targetRef.current) {
            observer.observe(targetRef.current);
        }

        return () => {
            if (targetRef.current) {
                observer.unobserve(targetRef.current);
            }
        };
    }, [shouldLoad, isFirstInteraction]);

    return (
        <div ref={targetRef}>
            {shouldLoad ? (
                loadComponent()
            ) : (
                <></>
            )}
        </div>
    );
}

export default LazyLoadComponent;
