/** @format */

import stl from "../../HomePage.module.css";
import HeroArea2 from "./HeroArea2";
import useIsMobile from "@hooks/useIsMobile";
import { useLang } from "../../../../common/Contexts/LanguageContext";
import { FC } from "react";
import { IHero } from "../../../../../types/models/marketing";
import { thumbUrl } from "../../../../utils/imagesHelpers";
import ProgressiveImage from "@componentsShared/ProgressiveImage";

type Props = {
  data: { heros: IHero[] };
};
const SingleImageBanner: FC<{ hero: IHero }> = ({ hero }) => {
  const isMobile = useIsMobile();

  const lang = useLang();

  return (
    <div
      style={{
        background:
          hero.gradient_direction && hero.gradient_enable !== 0
            ? `linear-gradient(${hero.gradient_direction}, ${hero.primary_banner_color} 0%, ${hero.secondary_banner_color} 100%)`
            : hero.primary_banner_color,
      }}
      className={stl.slide}
    >
      {isMobile ? (
        <ProgressiveImage
          loading="eager"
          src={thumbUrl(hero.banner_img_mobile, "hero-v1", "0x426", true)}
          progressiveSmallImage={thumbUrl(
            hero.banner_img_mobile,
            "hero-v1",
            "0x154",
            true
          )}
          width={390}
          height={366}
          alt=""
        />
      ) : (
        <ProgressiveImage
          loading="eager"
          src={thumbUrl(hero.banner_img_desktop, "hero-v1", "0x960", true)}
          progressiveSmallImage={thumbUrl(
            hero.banner_img_desktop,
            "hero-v1",
            "0x300",
            true
          )}
          width={1200}
          height={675}
          alt=""
        />
      )}

      {hero.cta_enable !== 0 && (
        <div className={["SlideLinkContainer", stl.slidContw].join(" ")}>
          <a
            className="redBtn22"
            style={{
              backgroundColor: hero.cta_background || undefined,
              borderColor: hero.cta_background || undefined,
            }}
            href={`${lang === "en" ? "/en" : ""}${
              hero.cta_filter ? "/filters" + hero.cta_filter : hero.cta_url_path
            }`}
          >
            {hero.cta_text}
          </a>
        </div>
      )}
    </div>
  );
};
const MultiImageBanner: FC<{ heros: IHero[] }> = ({ heros }) => {
  const isMobile = useIsMobile();
  const lang = useLang();
  return (
    <HeroArea2>
      {heros.map((item, idx) => {
        const gradientColor =
          item.gradient_direction && item.gradient_enable !== 0
            ? `linear-gradient(${item.gradient_direction}, ${item.primary_banner_color} 0%, ${item.secondary_banner_color} 100%)`
            : item.primary_banner_color;
        return (
          <div
            key={"U" + idx}
            style={{ background: gradientColor }}
            className={stl.slide}
          >
            {isMobile ? (
              <ProgressiveImage
                loading="eager"
                fetchpriority="high"
                src={thumbUrl(item.banner_img_mobile, "hero-v1", "0x426", true)}
                progressiveSmallImage={thumbUrl(
                  item.banner_img_mobile,
                  "hero-v1",
                  "0x154",
                  true
                )}
                width={390}
                height={366}
                alt=""
              />
            ) : (
              <ProgressiveImage
                loading="eager"
                fetchpriority="high"
                src={thumbUrl(
                  item.banner_img_desktop,
                  "hero-v1",
                  "0x960",
                  true
                )}
                progressiveSmallImage={thumbUrl(
                  item.banner_img_desktop,
                  "hero-v1",
                  "0x300",
                  true
                )}
                width={1200}
                height={675}
                alt={item?.cta_text?item?.cta_text:"banner Image"}
              />
            )}

            {item.cta_enable !== 0 && (
              <div className={"SlideLinkContainer"}>
                <a
                  style={{
                    backgroundColor: item.cta_background
                      ? item.cta_background
                      : undefined,
                    borderColor: item.cta_background
                      ? item.cta_background
                      : undefined,
                  }}
                  className="redBtn22 "
                  href={`${lang === "en" ? "/en" : ""}${
                    item.cta_filter
                      ? "/filters" +
                        item.cta_filter +
                        `#referral_location=Home&browse_variant=hero_area&variant_value=${item?.id}`
                      : item.cta_url_path +
                        `#referral_location=Home&browse_variant=hero_area&variant_value=${item?.id}`
                  }`}
                >
                  {item.cta_text}
                </a>
              </div>
            )}
          </div>
        );
      })}
    </HeroArea2>
  );
};
const HeroAreaWrapper: FC<Props> = (props) => {
  const { heros } = props.data;
  return (
    <>
      {heros?.length === 1 && <SingleImageBanner hero={heros[0]} />}
      {heros?.length > 1 && <MultiImageBanner heros={heros} />}
    </>
  );
};
export default HeroAreaWrapper;
