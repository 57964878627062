import { createInternalUrlWithParams } from "../../utils/common";
import stl from "./HomePage.module.css";
import HomePageLoader from "./HomePageLoader";
import { useTranslation } from "@i18n";
import "react-lazy-load-image-component/src/effects/blur.css";
import LazyLoadComponent from "../../common/hooks/LazyLoadComponent";
import loadable from "@loadable/component";
import SearchByMakeContainer from "./components/SearchByMakeContainer/SearchByMakeContainer";
import { useLang } from "../../common/Contexts/LanguageContext";
import HeroAreaWrapper from "./components/HeroArea2/HeroAreaWrapper";
import UspSlider from "./components/usp_slider/UspSlider";

const fallback = {
  fallback: null,
};
const SearchByRangeContainer = loadable(
  () => import("./components/SearchByRangeContainer/SearchByRangeContainer"),
  fallback
);
const SuggestionsContainerOnline = loadable(
  () => import("../PostComponent/components/SuggestionsContainerOnline"),
  fallback
);
const SuggestionsContainer = loadable(
  () => import("../PostComponent/components/SuggestionsContainer"),
  fallback
);

const OffersWrapper = loadable(
  () => import("./components/OfferCars/OffersWrapper"),
  fallback
);

const SellTradeHome = loadable(
  () => import("./components/SellTradeHome/SellTradeHome"),
  fallback
);

const TamweelOptions = loadable(
  () => import("./components/TamweelOptions/TamweelOptions"),
  fallback
);

const IntagarmContainer = loadable(
  () => import("./components/IntagarmContainer/IntagarmContainer"),
  fallback
);

const RatingSection = loadable(
  () => import("./components/RatingSection/RatingSection"),
  fallback
);
const AnyQust = loadable(
  () => import("./components/AnyQust/AnyQust"),
  fallback
);
const RenewCars = loadable(
  () => import("./components/RenewCars/RenewCars"),
  fallback
);
const FAQ = loadable(() => import("./components/FAQ/FAQ"), fallback);

const HomePage = (props) => {
  const { t } = useTranslation(["web_home"]);
  const {
    makes,
    userFeedback,
    faqs,
    contactUs,
    suggestion,
    deals,
    testimonials,
  } = props.data;

  const lang = useLang();

  if (props.flashLoading) return <HomePageLoader />;

  if (props.selectedTab === 2)
    return (
      <div className={[stl.CustomContainer, stl.dtCm].join(" ")}>
        <LazyLoadComponent
          loadComponent={() => (
            <SuggestionsContainer
              title={t("حراج سيارات للبيع في السعودية", {
                ns: "web_home",
              })}
              revampLink={createInternalUrlWithParams("/filters", {
                is_online: 0,
              })}
              similarPosts={suggestion.posts}
            />
          )}
        />
      </div>
    );

  return (
    <>
      {[0, 1].includes(props.selectedTab) && <HeroAreaWrapper {...props} />}

      {![0, 1].includes(props.selectedTab) && <div className={stl.mTopX} />}

      <UspSlider usp_slider={props?.data?.homepage_sliders?.[0]} isEager />

      <SearchByMakeContainer
        selectedTab={props.selectedTab}
        carMakes={makes}
        onlineSearch={props.isNew}
        isNew={props.isNew}
        moreLink={createInternalUrlWithParams("/autos", {}, lang)}
      />

      <LazyLoadComponent
        loadComponent={() => <SearchByRangeContainer {...props} />}
      />

      <div className="m-show">
        <div className={stl.lineSepMob}></div>
      </div>

      <LazyLoadComponent
        loadComponent={() => (
          <UspSlider usp_slider={props?.data?.homepage_sliders?.[1]} />
        )}
      />

      <div className={stl.CustomContainer}>
        {suggestion && (
          <LazyLoadComponent
            loadComponent={() => (
              <SuggestionsContainerOnline
                title={suggestion.title}
                similarPosts={suggestion.posts}
                isNew={props.selectedTab === 1}
                btnTxt={
                  props.selectedTab === 0
                    ? t("شاهد جميع السيارات المستعملة", {
                        ns: "web_home",
                      })
                    : t("شاهد جميع السيارات الجديدة", {
                        ns: "web_home",
                      })
                }
              />
            )}
          />
        )}
        <a
          className="moreSameCar"
          href={createInternalUrlWithParams(
            "/filters",
            {
              ["condition_id"]: props.selectedTab === 1 ? 1 : 0,
            },
            lang
          )}
        />
      </div>

      <div className={stl.mobileFlip}>
        <div className={[stl.CustomContainer, "m-hide"].join(" ")}>
          {deals && (
            <LazyLoadComponent
              loadComponent={() => (
                <OffersWrapper
                  isNew={props.isNew}
                  deals={deals}
                  title={t("تصنيفات مميزة", {
                    ns: "web_home",
                  })}
                />
              )}
            />
          )}
        </div>

        {!props.isNew && (
          <LazyLoadComponent
            renderOnSSR={false}
            loadComponent={() => <RenewCars />}
          />
        )}

        <div
          className={[stl.CustomContainer, "m-show", stl.undoCustomWidth].join(
            " "
          )}
        >
          {deals && (
            <LazyLoadComponent
              loadComponent={() => (
                <OffersWrapper
                  isNew={props.isNew}
                  deals={deals}
                  title={t("تصنيفات مميزة", { ns: "web_home" })}
                />
              )}
            />
          )}
        </div>
      </div>

      <LazyLoadComponent loadComponent={() => <SellTradeHome />} />

      <LazyLoadComponent
        loadComponent={() => <TamweelOptions isNew={props.isNew} />}
      />

      <LazyLoadComponent
        renderOnSSR={false}
        loadComponent={() => <IntagarmContainer testimonials={testimonials} />}
      />
      <LazyLoadComponent
        renderOnSSR={false}
        loadComponent={() => <RatingSection data={userFeedback} />}
      />
      <LazyLoadComponent loadComponent={() => <FAQ faqs={faqs} isHomePage />} />

      <div className="m-hide">
        <LazyLoadComponent
          loadComponent={() => <AnyQust contact={contactUs} />}
        />
      </div>
    </>
  );
};
export default HomePage;
