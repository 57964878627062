import { useState, useEffect, FC } from "react";
import useIsFirstInteraction from "../common/hooks/useIsFirstInteraction";
import { CDN_LINK } from "@globalConfig";
import ZoomableImage from "@/components/carTracking/components/newPostBigSlider/components/ZoomableImage";

// Extends img element attributes to include custom props for smallImage and bigImage
interface ProgressiveImageProps extends HTMLImageElement {
  progressiveSmallImage: string | undefined;
  fetchpriority?: "low" | "high" | "auto";
  waitUntilInteractive?: boolean;
  noInitialDiv?: boolean;
  errorImage?: string;
  hasZoom?: boolean;
  loading?: "eager" | "lazy" | undefined; // Define loading prop
}

const DEFAULT_ERROR_IMAGE = CDN_LINK + "/assets/images/no_img.jpg";
const MAX_ERRORS_COUNT = 3;
const counters = {};
const onError =
  (imagePath: string, errorImage: string) =>
  ({ currentTarget }) => {
    counters[imagePath] = counters[imagePath] || 0;
    if (counters[imagePath] >= MAX_ERRORS_COUNT) {
      return;
    }
    counters[imagePath]++;

    currentTarget.onerror = null; // prevents looping
    currentTarget.src = errorImage || DEFAULT_ERROR_IMAGE;
  };

const ProgressiveImage: FC<ProgressiveImageProps> = ({
  progressiveSmallImage = null,
  waitUntilInteractive = false,
  noInitialDiv = false,
  errorImage,
  src,
  hasZoom = false,
  loading, // pass loading attribute
  ...imgProps
}) => {
  const initialSrc = waitUntilInteractive ? null : src;
  // const Tag = hasZoom ? "ZoomableImage" : "";
  const [currentImage, setCurrentImage] = useState<string | null>(initialSrc);
  const [currentLoading, setCurrentLoading] = useState<
    "eager" | "lazy" | undefined
  >(loading); // Initialize with the provided loading prop
  const isFirstInteraction = useIsFirstInteraction();

  useEffect(() => {
    if (waitUntilInteractive && isFirstInteraction) {
      setCurrentImage(src);
      // setCurrentLoading(undefined); // Update loading prop
    }
  }, [isFirstInteraction]);
  useEffect(() => {
    setCurrentImage(src);
    //  setCurrentLoading(undefined); // Update loading prop
  }, [src]); // Add loading to dependencies

  // to Handel SVG
  if (!currentImage) {
    if (imgProps.width && imgProps.height) {
      return (
        <div
          className={imgProps.className}
          style={{
            width: noInitialDiv ? "auto" : imgProps.width + "px",
            height: noInitialDiv ? "auto" : imgProps.height + "px",
            backgroundColor: "transparent",
            display: "inline-block",
          }}
        />
      );
    }
    return null;
  }

  return hasZoom ? (
    <ZoomableImage>
      <img
        className="hasEvents"
        src={currentImage}
        // @ts-ignore
        style={{
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundImage: progressiveSmallImage
            ? `url("${progressiveSmallImage}")`
            : "none",
          backgroundColor: "transparent",
          backgroundPosition: "center",
        }}
        // @ts-ignore
        loading={currentLoading} // Use currentLoading state
        {...imgProps}
      />
    </ZoomableImage>
  ) : (
    <img
      src={currentImage}
      // @ts-ignore
      style={{
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundImage: progressiveSmallImage
          ? `url("${progressiveSmallImage}")`
          : "none",
        backgroundColor: "transparent",
        backgroundPosition: "center",
      }}
      // @ts-ignore
      loading={currentLoading} // Use currentLoading state
      {...imgProps}
    />
  );
};

export default ProgressiveImage;
