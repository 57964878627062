import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import cookieClient from "react-cookies";
import { useState } from "react";

export default function useIsWebView() {
  const location = useLocation();
  const queryString = location.search;

  const params = new URLSearchParams(queryString);

  const [isWebView, setIsWebView] = useState(!!params.get("webview"));

  useEffect(() => {
    if (cookieClient.load("webview")) {
      setIsWebView(true);
    } else if (isWebView) {
      cookieClient.save("webview", "1", {
        path: "/",
        maxAge: 3600 * 24 * 365 * 10, // Cookie will expire after 10 years
        sameSite: true,
      });
    }
  }, [isWebView]);

  return isWebView;
}
