/** @format */

import {createContext, useContext} from "react";

// Create a Context for the language
const LanguageContext = createContext();

export const useLang = () => {
  if (typeof window !== "undefined") {
    return window.location.pathname.match(/^(\/en)/) ? "en" : "ar";
  }
  return useContext(LanguageContext);
};

export default LanguageContext;
