import { CDN_LINK } from "@globalConfig";
import {
  createInternalUrlWithParams,
  createSeoUrlWithParams,
} from "../../../utils/common";
import { Link } from "react-router-dom";
import stl from "./Header.module.css";
import { useTranslation } from "@i18n";
import loadable from "@loadable/component";
import { useLang } from "../../../common/Contexts/LanguageContext";
import useIsMobile from "@hooks/useIsMobile";
import useIsBrowser from "@hooks/useIsBrowser";
import { FC } from "react";
import ProgressiveImage from "@componentsShared/ProgressiveImage";

const fallback = <>Loading...</>;
const UnbxdTextSearch = loadable(
  () =>
    import(
      "../../SearchComponent/UnbxdComponents/UnbxdAsideFilters/components/UnbxdTextSearch/UnbxdTextSearch"
    ),
  fallback
);
const DownloadAppBanner = loadable(
  () => import("./DownloadAppBanner/DownloadAppBanner"),
  fallback
);

interface CampaignLineProps {
  hideBannerCssOnly?: any;
}

const CampaignLine = loadable(
  ({ hideBannerCssOnly }: CampaignLineProps) =>
    import("../../sharedComponents/CampaignLine/CampaignLine"),
  fallback
);

type HeaderProps = {
  custommobileHeaderFragmentOnly?: boolean;
  custommobileHeader?: string;
  revampedHEading?: boolean;
  showSearchBar?: boolean;
  className?: string;
  hideHeaderOnmobile?: boolean;
  straightHeader?: boolean;
  hasCampaign?: boolean;
  campData?: {
    title?: string;
  };
  tracking?: boolean;
  mobileHasBack?: boolean;
  logoNotClickable?: boolean;
  mobileNoLogo?: boolean;
  updateTabs?: (tab: number) => void;
  headHideLinks?: boolean;
  linksForSearch?: boolean;
  hideOrangeHeadBtn?: boolean;
  hideBannerCssOnly?: boolean;
  user?: {
    dealer_id?: number;
  };
  loggedin?: boolean;
  hideBurgerMenu?: boolean;
  burgrMenuisCncl?: boolean;
  hideAppBanner?: boolean;
  deal?: boolean;
  selectedTab?: number | string;
  mobileHasTitle?: string;
  toggleMenu?: () => void;
  notification?: boolean;
  hideSideMenue?: boolean;
  loading?: boolean;
  setUnbxdPage?: (page: number) => void;
};
const Header: FC<HeaderProps> = (props) => {
  const { t } = useTranslation(["web_header", "web_common"]);
  const lang = useLang();
  const isMobile = useIsMobile();
  const isBrowser = useIsBrowser();

  return (
    <>
      {props.custommobileHeaderFragmentOnly
        ? props.custommobileHeader
        : props.custommobileHeader && (
            <div
              className={
                props.custommobileHeader
                  ? ["m-show", stl.custommobileHeader].join(" ")
                  : undefined
              }
            >
              {props.custommobileHeader}
            </div>
          )}
      <header
        className={[
          "siteHeader",
          !props.revampedHEading && props.showSearchBar
            ? "searchSiteHEader"
            : undefined,
          props.revampedHEading ? stl.revampedSearch : undefined,
          props.className ? props.className : "",
          props.hideHeaderOnmobile ? "m-hide" : undefined,
          props.straightHeader ? stl.straightHeader : "",
        ].join(" ")}
      >
        {props.hasCampaign && props.campData && props.campData.title && (
          <CampaignLine
            hideBannerCssOnly={props.hideBannerCssOnly}
            data={props.campData}
          />
        )}

        <div
          className={[
            "container",
            "innerHdr",
            props.mobileHasTitle ? "mobHasTitleContainer" : "",
          ].join(" ")}
        >
          {!props.tracking ? (
            <>
              <span
                className={`${
                  props.showSearchBar ? "SearchHEader" : undefined
                } ${stl.backFlex}`}
              >
                {props.mobileHasBack &&
                  isBrowser &&
                  history.state != null &&
                  window.location.href.indexOf("fromReport") === -1 && (
                    <span
                      onClick={() => {
                        document.querySelector(".backtolisting") != null
                          ? (window.location.href = document
                              .querySelector(".backtolisting")
                              .querySelector("a")
                              .getAttribute("href"))
                          : history.back();
                      }}
                      className={["m-show", stl.mobileBackBlueArr].join(" ")}
                    >
                      <img
                        src={CDN_LINK + "/assets/images/mobileBackBlueArr.svg"}
                        alt="back"
                      />
                    </span>
                  )}
                <Link
                  className={[
                    props.logoNotClickable ? stl.disableLink : undefined,
                    props.mobileNoLogo ? "m-hide" : "",
                  ].join(" ")}
                  onClick={() =>
                    props.updateTabs ? props.updateTabs(0) : undefined
                  }
                  to={createInternalUrlWithParams("/")}
                >
                  <img
                    width={83}
                    height={38}
                    className={[stl.logoSizeRev, "hasEvents"].join(" ")}
                    src={
                      !props.revampedHEading && props.showSearchBar && isMobile
                        ? CDN_LINK + "/assets/images/logoWhiteN.svg"
                        : CDN_LINK + "/assets/images/logoN.svg"
                    }
                    alt="syarah logo icon"
                    onClick={() =>
                      props.updateTabs ? props.updateTabs(0) : undefined
                    }
                  />
                </Link>
              </span>
              <p className="m-show mobHasTitle">{props.mobileHasTitle}</p>
            </>
          ) : (
            <a onClick={() => history.back()} className={stl.trackingLink}>
              <img
                src={CDN_LINK + "/assets/images/trackinh-header.svg"}
                alt=""
              />
              {t("العودة لتتبع السيارة", { ns: "web_header" })}
            </a>
          )}

          {props.showSearchBar && (
            <UnbxdTextSearch setPage={props.setUnbxdPage} />
          )}

          {!props.hideSideMenue && (
            <ul>
              {!props.headHideLinks && (
                <>
                  {props.linksForSearch ? (
                    <>
                      <li className={[stl.revampLink].join(" ")}>
                        <span
                          id="QA_Auto_UsedTab"
                          className="QA_Auto_UsedTab"
                          onClick={() =>
                            (document.location.href = `${
                              lang === "en" ? "/en" : "/"
                            }`)
                          }
                        >
                          <span>
                            {t("مستعملة مضمونة", { ns: "web_header" })}
                          </span>
                        </span>
                      </li>
                      <li className={[stl.revampLink].join(" ")}>
                        <span
                          id="QA_Auto_NewTab"
                          className="QA_Auto_NewTab"
                          onClick={() => {
                            document.location.href = `/${
                              lang === "en" ? "en/" : ""
                            }home/new-cars`;
                          }}
                        >
                          <span>{t("جديدة", { ns: "web_header" })}</span>
                        </span>
                      </li>
                    </>
                  ) : props.revampedHEading ? (
                    <>
                      <li
                        className={[
                          stl.revampLink,
                          !props.loading && props.selectedTab === 0
                            ? stl.activeLink
                            : undefined,
                        ].join(" ")}
                      >
                        <span
                          id="QA_Auto_UsedTab"
                          className="QA_Auto_UsedTab"
                          onClick={() => props.updateTabs(0)}
                        >
                          {t("مستعملة مضمونة", { ns: "web_header" })}
                        </span>
                      </li>
                      <li
                        className={[
                          stl.revampLink,
                          !props.loading && props.selectedTab === 1
                            ? stl.activeLink
                            : undefined,
                        ].join(" ")}
                      >
                        <Link
                          to={createInternalUrlWithParams("/home/new-cars")}
                          id="QA_Auto_NewTab"
                          onClick={() => props.updateTabs(1)}
                        >
                          {t("جديدة", { ns: "web_header" })}
                        </Link>
                      </li>
                    </>
                  ) : (
                    <>
                      <li>
                        <Link
                          to={createSeoUrlWithParams(
                            "/filters",
                            {
                              is_online: 0,
                              is_new: 1,
                            },
                            lang
                          )}
                        >
                          {t("سيارات جديدة", { ns: "web_common" })}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={createSeoUrlWithParams(
                            "/filters",
                            {
                              is_online: 0,
                              is_new: 0,
                            },
                            lang
                          )}
                        >
                          {t("سيارات مستعملة", { ns: "web_common" })}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={createSeoUrlWithParams(
                            "/filters",
                            {
                              is_online: 1,
                            },
                            lang
                          )}
                          className={"onlineHdrLink"}
                        >
                          {t("سيارة أونلاين", { ns: "web_common" })}
                        </Link>
                      </li>
                    </>
                  )}
                </>
              )}

              {!props.hideOrangeHeadBtn && (
                <li
                  className={["sellCarNewBtn sellNewBtn", stl.newBtnOrng].join(
                    " "
                  )}
                >
                  <Link
                    to={createInternalUrlWithParams(
                      lang === "en"
                        ? "/sell-tradein-your-car"
                        : "/بدل-بيع-سيارتك",
                      {},
                      lang
                    )}
                    state="header"
                    className={stl.sellCarBtn}
                  >
                    {(props.user && props.user.dealer_id) || props.loggedin
                      ? t("اعرض سيارتك للبيع", {
                          ns: "web_aside_menu",
                        })
                      : t("بدل/بيعنا سيارتك", {
                          ns: "web_aside_menu",
                        })}
                  </Link>
                </li>
              )}
              <li className={stl.lastLiRev}>
                {!props.hideBurgerMenu && (
                  <>
                    {props.burgrMenuisCncl && isMobile ? (
                      <span
                        onClick={() => window.history.back()}
                        className="cnclHDR"
                      >
                        {t("إلغاء", { ns: "web_common" })}
                      </span>
                    ) : !props.tracking ? (
                      <span
                        onClick={props.toggleMenu}
                        className={`sideMenuToggle m-hide ${
                          props.loggedin && props.notification
                            ? "notiMsgRed"
                            : ""
                        }`}
                      >
                        {!isMobile && (
                          <ProgressiveImage
                            data-id="burgerMenu"
                            fetchpriority="low"
                            className={[
                              props.revampedHEading
                                ? stl.MenuIcnSizeRev
                                : undefined,
                              "hasEvents",
                            ].join(" ")}
                            src={
                              !props.revampedHEading &&
                              props.showSearchBar &&
                              isMobile
                                ? CDN_LINK +
                                  "/assets/images/menuToggleWhite.svg"
                                : CDN_LINK + "/assets/images/menuToggle.svg"
                            }
                            width={24}
                            height={16}
                            alt="T"
                          />
                        )}
                      </span>
                    ) : null}
                  </>
                )}
              </li>
            </ul>
          )}
        </div>
        {isMobile && !props.hideAppBanner && <DownloadAppBanner />}
        {!props.deal && props.revampedHEading && (
          <ul className={[stl.mobileTabs, "m-show"].join(" ")}>
            <li
              className={[
                stl.revampLink,
                !props.loading && parseInt(props.selectedTab) === 0
                  ? stl.activeLink
                  : undefined,
              ].join(" ")}
            >
              <span
                id="QA_Auto_UsedTab_mobile"
                className="QA_Auto_UsedTab_mobile"
                onClick={() => props.updateTabs(0)}
              >
                {t("مستعملة مضمونة", { ns: "web_header" })}
              </span>
            </li>
            <li
              className={[
                stl.revampLink,
                !props.loading && props.selectedTab === 1
                  ? stl.activeLink
                  : undefined,
              ].join(" ")}
            >
              <Link
                to={createInternalUrlWithParams("/home/new-cars")}
                id="QA_Auto_NewTab_mobile"
                className="QA_Auto_NewTab_mobile"
                onClick={() => props.updateTabs(1)}
              >
                {t("جديدة", { ns: "web_header" })}
              </Link>
            </li>
          </ul>
        )}
      </header>
    </>
  );
};

export default Header;
